import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Spinner} from '@dropbox/dig-components/dist/progress_indicators';
import {Overlay, OverlayRefObject} from '@dropbox/dig-components/overlay';
import {Text} from '@dropbox/dig-components/typography';
import {Box} from '@dropbox/dig-foundations';
import {StatusButtonIcon} from 'components/DSYS/StatusButtonIcon';
import {formatTimeframe} from 'components/shared/TimeAgo';
import {t} from 'i18next';
import {useRef, useState} from 'react';
import {DASHBOARD_STATUSES, DASHBOARDS_SEGMENT_STATUS_COLORS} from 'views/dashboards/constants';
import {
  getGraphSegments,
  getStatusColorOverride,
  getStatusGoalCountsData,
  GoalCountsData,
} from 'views/dashboards/util';

import styles from './Dashboards.module.css';

interface DashboardsGoalsOverviewGraphProps {
  timeframe: string;
  height: number;
  width: string;
  goalCounts?: {[status: string]: number};
  onClick?: (status: string) => void;
  showTooltip?: boolean;
  showInfoOnHover?: boolean;
  isLoading?: boolean;
}

export const DashboardsGoalsOverviewGraph = (props: DashboardsGoalsOverviewGraphProps) => {
  const {height, width, goalCounts, isLoading, showTooltip, timeframe} = props;
  const {firstSegmentStatus, lastSegmentStatus} = getGraphSegments(goalCounts);

  const [isHovered, setHovered] = useState(false);
  const [tooltipGoalsCountData, setTooltipGoalsCountData] = useState<GoalCountsData | undefined>(
    undefined
  );

  const anchorRef = useRef(new VirtualAnchor());
  const arrowRef = useRef(null);
  const overlayRef = useRef<OverlayRefObject>(null);

  const handleMouseMove: React.MouseEventHandler<HTMLElement> = (evt) => {
    anchorRef.current.position = [evt.clientX + 130, evt.clientY + 55];
    if (overlayRef.current) overlayRef.current.update();
  };

  return (
    <>
      <Box
        className={styles.dashboardsGoalsOverviewGraph}
        backgroundColor="Background Subtle"
        borderRadius="Medium"
        borderColor="Border Subtle"
        borderStyle="Solid"
        borderWidth="1"
        style={{height, width}}
        onMouseMove={handleMouseMove}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {isLoading ? (
          <Box display="flex" justifyContent="center" width="100%">
            <Spinner size="xsmall" monochromatic />
          </Box>
        ) : (
          DASHBOARD_STATUSES.map((status) => {
            return (
              <DashboardsGoalsOverviewSegment
                key={`dashboards-goals-overview-status-segment__${status}`}
                status={status}
                setTooltipGoalsCountData={setTooltipGoalsCountData}
                isFirstSegment={status === firstSegmentStatus}
                isLastSegment={status === lastSegmentStatus}
                {...props}
              />
            );
          })
        )}
      </Box>
      {showTooltip && isHovered && tooltipGoalsCountData && (
        <Overlay
          auto
          ref={overlayRef}
          anchorRef={anchorRef}
          placement="top"
          offsetDistance={25}
          className={styles.dashboardsGoalsOverviewSegmentTooltip}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            style={{width: '150px'}}
          >
            <Box
              as="div"
              alignItems="flex-start"
              display="flex"
              style={{gap: '4px', marginBottom: '18px'}}
            >
              <StatusButtonIcon
                status={tooltipGoalsCountData.status}
                statusColorOverride={getStatusColorOverride(
                  tooltipGoalsCountData.status,
                  DASHBOARDS_SEGMENT_STATUS_COLORS
                )}
              />
              <LabelGroup
                size="small"
                withText={
                  <Text size="small" isBold>
                    {t(tooltipGoalsCountData.status)}
                  </Text>
                }
                withSubtext={formatTimeframe(timeframe)}
              />
            </Box>
            <Box style={{alignSelf: 'flex-end'}}>
              <LabelGroup
                size="small"
                withText={
                  <Text style={{fontSize: '23px'}} isBold>
                    {(goalCounts?.[tooltipGoalsCountData.status] ?? 0).toLocaleString()}
                  </Text>
                }
                withSubtext={
                  tooltipGoalsCountData
                    ? t('goals_overview_out_of', {
                        totalCount: (tooltipGoalsCountData.totalCount ?? 0).toLocaleString(),
                      })
                    : undefined
                }
              />
            </Box>
          </Box>
          <span className={styles.dashboardsGoalsOverviewSegmentTooltipArrow} ref={arrowRef} />
        </Overlay>
      )}
    </>
  );
};

// Copied from DIG Overlays documentation
class VirtualAnchor {
  position: [number, number];

  constructor() {
    this.position = [0, 0];
  }

  getBoundingClientRect() {
    return {
      height: 0,
      width: 0,
      top: this.position[1],
      right: this.position[0],
      bottom: this.position[1],
      left: this.position[0],
      x: 0,
      y: 0,
      toJSON: () => undefined,
    };
  }
}

export const DashboardsGoalsOverviewSegment = ({
  status,
  setTooltipGoalsCountData,
  goalCounts,
  onClick,
  height,
  isFirstSegment = false,
  isLastSegment = false,
  showInfoOnHover = false,
}: DashboardsGoalsOverviewGraphProps & {
  status: string;
  setTooltipGoalsCountData: (data: GoalCountsData | undefined) => void;
  isFirstSegment?: boolean;
  isLastSegment?: boolean;
}) => {
  const goalCountsData = getStatusGoalCountsData(status, goalCounts);
  const perc = goalCountsData?.percentageCeil ?? 0;

  const [isHovered, setHovered] = useState(false);

  const cssClasses = [styles.dashboardsGoalsOverviewSegment];
  if (isFirstSegment) {
    cssClasses.push(styles.dashboardsGoalsOverviewSegmentRoundedLeft);
  }
  if (isLastSegment) {
    cssClasses.push(styles.dashboardsGoalsOverviewSegmentRoundedRight);
  }

  const handleMouseEnter: React.MouseEventHandler<HTMLElement> = () => {
    setHovered(true);
    setTooltipGoalsCountData(goalCountsData);
  };

  const handleMouseLeave: React.MouseEventHandler<HTMLElement> = () => {
    setHovered(false);
  };

  return (
    <>
      {isHovered && showInfoOnHover && (
        <Box position="absolute" style={{top: '-26px'}}>
          <Text size="xsmall" color="subtle">{`${perc}% ${t(status)}`}</Text>
        </Box>
      )}
      {!!perc && (
        <>
          <Box
            className={cssClasses.join(' ')}
            style={{
              backgroundColor: goalCountsData?.segmentStatusColor,
              height: height - 2, // Subtract 2 for border
              width: `${perc}%`,
            }}
            onClick={() => onClick?.(status)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </>
      )}
    </>
  );
};
